<template>
  <div class="elv-report-page-container">
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { useReportStore } from '@/stores/modules/reports'

const reportStore = useReportStore()

onUnmounted(() => {
  reportStore.auxiliaryTypeList.length = 0
})
</script>

<style lang="scss" scoped>
.elv-report-page-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
